/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Suspense, useRef, useEffect } from "react";
import Image from "./Image";

const Video = ({ url: _url, muted, autoplay, placeholder, play = true }) => {
  let url;
  if (_url) {
    const plyr = useRef();
    const player = useRef();
    const options = {
      muted,
      autoplay,
    };
    const provider =
      _url.includes("youtube") || _url.includes("youtu.be")
        ? "youtube"
        : "vimeo";
    if (provider === "vimeo " && !_url.includes("vimeo")) {
      return null;
    }

    if (provider === "youtube") {
      if (_url.includes("embed")) {
        url = _url.split(/embed\//)[1].split('"')[0];
      } else {
        url = _url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
      }
    }

    if (provider === "vimeo") {
      url = _url.split("/").pop();
    }

    useEffect(() => {
      if (player.current) {
        if (play) {
          player.current.play();
        } else {
          player.current.pause();
        }
      }
    }, [play]);

    if (typeof window !== "undefined" && url) {
      // eslint-disable-next-line global-require
      const Plyr = require("plyr");
      useEffect(() => {
        setTimeout(() => {
          if (!player.current) {
            if (plyr.current && Plyr) {
              player.current = new Plyr(plyr.current);
              if (!play) {
                player.current.pause();
              }
            }
          }
        }, 500);
      }, []);
      // set up the player
      return (
        <Suspense
          fallback={
            <div>
              <Image {...placeholder} />
            </div>
          }
        >
          <div
            data-plyr-provider={provider}
            data-plyr-embed-id={url}
            ref={plyr}
            data-plyr-config={JSON.stringify(options)}
          />
        </Suspense>
      );
    }
    return (
      <div>
        <Image {...placeholder} />
      </div>
    );
  }
  return null;
};

export default Video;
