import { useStaticQuery, graphql } from "gatsby";
import { resolveLinks } from "@dataResolvers";

const query = graphql`
  query ErrorQuery {
    craft {
      globalSet(handle: "error") {
        ... on Craft_error_GlobalSet {
          heading0
          descriptor0
          link0 {
            url
            text
          }
          linkList {
            ... on Craft_linkList_link_BlockType {
              uid
              linkObject {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { heading0, descriptor0, link0, linkList } = craft.globalSet;

  return {
    heading: heading0,
    description: descriptor0,
    button: link0,
    links: resolveLinks(linkList),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
