const initialState = {
  modal: false,
  content: false,
  background: "bg-black",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        modal: true,
        mobileNav: action.mobileNav,
        showControls:
          typeof action.showControls !== "undefined"
            ? action.showControls
            : true,
        content: action.content,
        background: action.background || initialState.background,
        clearHash: false,
      };
    case "closeModal":
      return {
        ...state,
        mobileNav: false,
        modal: false,
        clearHash: true,
      };
    case "clearModalContent":
      if (state.content === false) break;
      return {
        ...state,
        content: false,
        background: initialState.background,
      };
    default:
      return state;
  }
  return state;
};

const modal = {
  reducer,
  initialState,
};

export default modal;
