import React from "react";

import { useAppState } from "@state";

import { Link } from "gatsby";

const AppLink = ({
  to: _url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
  partiallyActive = true,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [, dispatch] = useAppState();

  if (_url && typeof _url === "string") {
    const craftUrl = process.env.GATSBY_CRAFT_BASE_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = _url.replace(craftUrl, "").replace(siteUrl, "");

    const handleClick = e => {
      // e.preventDefault();
      if (onClick) {
        onClick(e);
      }
      // Trigger page transition
      dispatch({
        type: "startTransition",
        to: url,
      });
    };

    // handle internal links
    // usually internal router and/or transition
    if (
      url.includes(craftUrl) ||
      url.includes(siteUrl) ||
      (!url.includes("https://") &&
        !url.includes("http://") &&
        !url.includes("mailto:") &&
        !url.includes("tel:"))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      return (
        <Link
          className={`
            cursor-pointer
            font-normal
            ${_className} 
          `}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          title={title}
          name={title}
          to={url}
          onClick={handleClick}
          style={style}
        >
          {children}
        </Link>
      );
    }
    if (url.includes(process.env.GATSBY_OTHER_SITE)) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={`${_className || ""} cursor-pointer font-normal`}
          onClick={onClick}
          style={style}
        >
          {children}
        </a>
      );
    }
    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      <a
        href={
          url
            .replace(process.env.GATSBY_ASSET_ROOT, "/assets/")
            .replace(process.env.GATSBY_DOWNLOADS_ROOT, "/downloads/") || ""
        }
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={`${_className || ""} cursor-pointer font-normal`}
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${_className || ""} cursor-pointer`}
      style={style}
    >
      {children}
    </button>
  );
};

export default AppLink;
