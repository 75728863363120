/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/css/index.css";
import { AnimatePresence } from "framer-motion";

import React from "react";
import { Default } from "@layout";
import { AppStateProvider } from "@state";

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
);

export const wrapRootElement = ({ element }) => {
  return (
    <AppStateProvider>
      <Default>{element}</Default>
    </AppStateProvider>
  );
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};
