import React from "react";
import clsx from "clsx";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag = "span",
}) => {
  let Tag = tag;
  let classes;

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-serif text-5xl md:text-6xl leading-none";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-serif text-4xl md:text-5xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-serif text-3xl md:text-4xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-serif text-2xl md:text-2-5xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-serif text-xl md:text-2xl leading-snug";
      break;
    case "2xl":
      classes = "block text-2xl leading-snug font-sans";
      break;
    case "xl":
      classes = "block text-xl leading-snug font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-snug font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-xs leading-none font-sans font-normal uppercase tracking-0.5";
      break;
    case "caption":
      classes = "block text-xs leading-snug font-sans text-grey";
      break;
    default:
      classes = "";
      break;
  }
  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={child.key || i}
          className={clsx(classes, _className, {
            quotes,
            "prose prose-sm max-w-[70ch] lg:prose-base": richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        key={child.key || i}
        className={clsx(classes, _className, {
          quotes,
          "prose prose-sm max-w-[70ch] lg:prose-base": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

export default Text;
