import resolveImage from "./resolveImage";
import resolveImages from "./resolveImages";

const resolveCB = (contentBuilder, pageUid) => {
  if (contentBuilder && contentBuilder.length) {
    return contentBuilder.map(block => {
      const { typeHandle: type, uid } = block;
      switch (type) {
        case "heading":
          return {
            uid,
            type,
            heading: block.heading,
          };
        case "lead":
          return {
            uid,
            type,
            copy: block.copy,
          };
        case "copy":
          return {
            uid,
            type,
            copy: block.copy,
            background: block.showBackground,
          };
        case "blockQuote":
          return {
            uid,
            type,
            label: block.label,
            quote: block.quote,
            image: resolveImage(block.image),
            citation: block.citation,
            position: block.position,
          };
        case "images":
          return {
            uid,
            type,
            images: resolveImages(block.images),
          };
        case "button":
          return {
            uid,
            type,
            url: block.linkLink.url,
            text: block.linkLink.text,
            style: block.style,
          };
        case "video":
          return {
            uid,
            type,
            videoUrl: block.videoUrl,
            caption: block.caption,
          };
        case "contentGate":
          return {
            pageUid,
            uid,
            type,
            heading: block.heading,
            copy: block.copy,
          };
        case "downloadGate":
          return {
            pageUid,
            uid,
            type,
            heading: block.heading,
            copy: block.copy,
            submitButtonText: block.submitButtonText,
            downloadButtonText: block.downloadButtonText,
            download: block.download[0].url,
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolveCB;
