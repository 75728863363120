import React, { useRef } from "react";
import useDynamicSVGImport from "@hooks/useDynamicSVGImport";

const Icon = React.memo(function Icon({
  name,
  className: _className = "w-16 h-16",
  encode,
}) {
  const icon = useRef();
  const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
  return (
    <span ref={icon} className={`icon block fill-current ${_className}`}>
      {!encode && !loading && SvgIcon && <SvgIcon />}
      {encode && !loading && SvgIcon && (
        <img alt={name} className="block h-full w-full" src={SvgIcon} />
      )}
    </span>
  );
});

export default Icon;
