import resolveImage from "./resolveImage";

const resolveTestimonials = testimonials => {
  if (testimonials && testimonials.length > 0) {
    return testimonials.map(testimonial => {
      const {
        uid,
        image0,
        descriptor0,
        descriptor1,
        descriptor2,
        descriptor3,
        category,
      } = testimonial;
      return {
        uid,
        image: resolveImage(image0),
        context: descriptor0,
        quote: descriptor1,
        name: descriptor2,
        position: descriptor3,
        category,
      };
    });
  }
  return null;
};

export default resolveTestimonials;
