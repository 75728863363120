/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { motion } from "framer-motion";
import { useAppState } from "@state";
import { useDefaultsData } from "@staticQueries";
import { Meta } from "../atoms";

const Cta = loadable(() => import("../molecules/Cta"));
const Footer = loadable(() => import("../molecules/Footer"));

const PageContainer = ({ children, className, path, showCta = true, meta }) => {
  const { cta } = useDefaultsData();
  // eslint-disable-next-line no-empty-pattern
  const [{ layout }, dispatch] = useAppState();

  const { isTransitioning, hash } = layout;
  // handle scroll effect when visiting cross page anchor links
  useEffect(() => {
    if (!isTransitioning && hash) {
      document.getElementById(hash)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [hash, isTransitioning]);

  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.2,
  };

  const pageVariants = {
    enter: {
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  // const contentVariants = {
  //   enter: {
  //     transition: {
  //       ...defaults,
  //     },
  //   },
  //   exit: {
  //     transition: {
  //       ...defaults,
  //     },
  //   },
  // };

  return (
    <motion.div
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={pageVariants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
            if (window.location) {
              dispatch({
                type: "setHash",
                hash: window.location.hash?.split("#")[1] || undefined,
              });
            }
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className="relative -mt-[6rem]"
    >
      <div className="absolute inset-y-0 left-0 z-[100] xl:w-8" />
      <div className="absolute inset-y-0 right-0 z-[100] xl:w-8" />
      <main
        className={clsx(
          className,
          "flex min-h-screen flex-grow flex-col overflow-x-clip"
        )}
      >
        {children}
      </main>
      {showCta && <Cta {...cta} />}
      <Footer />
      {meta && <Meta {...meta} />}
    </motion.div>
  );
};

export default PageContainer;
