import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query ContactInfoQuery {
    craft {
      globalSet(handle: "contact") {
        ... on Craft_contact_GlobalSet {
          descriptor0
          descriptor1
          list0 {
            item
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { descriptor0, descriptor1, list0 } = craft.globalSet;

  return {
    email: descriptor0,
    phone: descriptor1,
    addresses: list0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
