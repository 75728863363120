const resolveLinks = links =>
  links.map(link => {
    return {
      uid: link.uid,
      type: link.typeHandle,
      url: link.linkObject.url,
      text: link.linkObject.text,
    };
  });

export default resolveLinks;
