import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllTopicsQuery {
    craft {
      categories(group: "articleTopics") {
        ... on Craft_articleTopics_Category {
          uid
          title
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const topics = craft.categories.map(t => {
    return {
      uid: t.uid,
      title: t.title,
    };
  });

  return topics;
};
const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
