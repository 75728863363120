import React from "react";
import loadable from "@loadable/component";
import { PageSizeObserver } from "@base";

const Helmet = loadable(() => import("react-helmet"));
const Announcement = loadable(() => import("../molecules/Announcement"));
const Header = loadable(() => import("../organisms/Header"));
const Modal = loadable(() => import("../molecules/Modal"));
const GdprBanner = loadable(() => import("../molecules/GdprBanner"));

const DefaultLayout = ({ children }) => {
  // the layout
  return (
    <div id="app_layout">
      <PageSizeObserver />
      <Helmet>
        {/* uncomment to load typekit */}
        {/* <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} /> */}
        {/* uncomment to allow social embeds */}
        {/* <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" /> */}
      </Helmet>
      <Announcement />
      <Header />
      {/* layout border */}
      {children}
      <Modal />
      <GdprBanner />
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

export default DefaultLayout;
