/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import Icon from "./Icon";

const Dropdown = ({
  model,
  options,
  placeholder = "Select one",
  inverse,
  size = "md",
  sans,
}) => {
  if (model) {
    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
      setIsActive(!isActive);
    };
    const [value, setValue] = model;
    const dropdown = useRef();
    const dropdownControl = useAnimation();

    // close menu if it's open and you click outside of it
    const handleClickOutside = event => {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // change state and close menu when a dropdown item is selected
    useEffect(() => {
      setIsActive(false);
    }, [value]);

    // animations
    useEffect(() => {
      if (isActive) {
        dropdownControl.start({
          height: "auto",
          opacity: 1,
          display: "block",
          transition: {
            type: "tween",
            duration: 0.2,
            ease: "easeIn",
          },
        });
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
      }
      if (!isActive) {
        dropdownControl.start({
          height: 0,
          opacity: 0,
          transition: {
            type: "tween",
            duration: 0.2,
            ease: "easeIn",
          },
          transitionEnd: {
            display: "hidden",
          },
        });
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      }
    }, [isActive]);

    // eslint-disable-next-line global-require

    let textSize;
    let iconSize;

    switch (size) {
      case "xs":
        textSize = "text-xs";
        iconSize = "w-1 h-1";
        break;
      case "sm":
        textSize = "text-sm";
        iconSize = "w-2 h-2";
        break;
      case "md":
        textSize = "text-md";
        iconSize = "w-3 h-3";
        break;
      case "lg":
        textSize = "text-lg";
        iconSize = "w-4 h-4";
        break;
      default:
        textSize = "text-md";
        iconSize = "w-3 h-3";
        break;
    }

    return (
      <div
        className={clsx("relative", {
          "font-sans": sans,
          "font-serif": !sans,
        })}
      >
        <motion.button
          type="button"
          ref={dropdown}
          onTap={toggleActive}
          className={clsx(
            "flex w-full items-center",
            {
              "px-3 py-1": size === "xs",
              "px-4 py-2": size !== "xs",
              "bg-black text-white": inverse,
              "bg-white text-black": !inverse,
            },
            textSize
          )}
        >
          <span className="mr-2 whitespace-nowrap text-left">
            {value != null && value !== "" ? options[value].title : placeholder}
          </span>
          {/* dropdown Icon */}
          <Icon
            name="chevron"
            className={clsx(
              "icon transform text-white duration-200",
              {
                "rotate-90": isActive,
                "rotate-0": !isActive,
              },
              iconSize
            )}
          />
        </motion.button>
        <div className="relative -mt-px w-full min-w-[8rem]">
          <motion.ul
            animate={dropdownControl}
            className={clsx(
              "absolute left-0 right-0 z-40 h-0 overflow-hidden border-b border-l border-r",
              {
                "bg-black text-white": inverse,
                "bg-white text-black": !inverse,
                "shadow-xl": isActive,
                "shadown-none": !isActive,
              },
              textSize
            )}
          >
            {placeholder.length > 1 && (
              <li>
                <button
                  type="button"
                  className={clsx(
                    "w-full border-t px-4 py-2 text-left text-grey",
                    textSize
                  )}
                  disabled
                >
                  {placeholder}
                </button>
              </li>
            )}
            {options.map((option, i) => {
              return (
                <li key={option.uid ? option.uid : option.title + String(i)}>
                  <motion.button
                    onTap={() => setValue(i)}
                    type="button"
                    className="w-full border-t px-4 py-2 text-left duration-200 hover:bg-yellow hover:text-black"
                  >
                    {option.title}
                  </motion.button>
                </li>
              );
            })}
          </motion.ul>
        </div>
      </div>
    );
  }
  return null;
};

export default Dropdown;
