import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    craft {
      globalSet(handle: "announcement") {
        ... on Craft_announcement_GlobalSet {
          boolean0
          heading0
          descriptor0
          link0 {
            url
            text
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { boolean0, heading0, descriptor0, link0 } = craft.globalSet;
  return {
    enabled: boolean0,
    heading: heading0,
    description: descriptor0,
    button: {
      url: link0.url,
      text: link0.text,
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
