import { useEffect, useState, useCallback } from "react";
import qs from "query-string";
import { useAppState } from "@state";

/**
 * Merge preview data and non-preview data (static data) so we can preview content, which
 * typically involves both preview and static data.
 *
 */

const mergePreviewData = (staticData, _query, variables) => {
  const [, dispatch] = useAppState();
  const [data, setData] = useState(staticData);

  let query = _query.replace(/Craft_/g, "");
  if (query.includes("craft {")) {
    query = query.replace("craft {", "").replace(/}([^}]*)$/g, "");
  }

  const handleError = useCallback(error => {
    // eslint-disable-next-line
    console.log(error);
    dispatch({ type: "setPreviewMode", previewMode: false });
  });

  const handleSuccess = async response => {
    const previewData = await response.json();
    // TODO: May need additional logic to merge previewData with staticData here.
    // If so, update comments above with notes about how that works.
    // If not, then change the name of this function to something that makes sense.

    const { data: _data, errors } = previewData;

    if (errors) {
      handleError(errors);
    } else {
      setData({ craft: _data });
      dispatch({ type: "setPreviewMode", previewMode: true });
    }
  };

  useEffect(() => {
    // Check if we have previewData token in the url
    const { token } = qs.parse(window.location.search);

    // If token, then make request to get draft entry associated with the token
    if (token) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });

      const url = `${process.env.GATSBY_CRAFT_API_URL}?token=${token}`;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_CRAFT_API_TOKEN}`,
      };

      const body = JSON.stringify({
        query,
        variables,
      });

      fetch(url, { method: "post", headers, body })
        .then(handleSuccess)
        .catch(handleError);
    }
  }, []);

  return data;
};

export default mergePreviewData;
