import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllTypesQuery {
    craft {
      categories(group: "articleTypes") {
        ... on Craft_articleTypes_Category {
          uid
          title
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const types = craft.categories.map(t => {
    return {
      uid: t.uid,
      title: t.title,
    };
  });

  return types;
};
const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
