import React from "react";
import clsx from "clsx";

import { AppLink } from "@base";
import Icon from "./Icon";

const Share = ({ title = "Share:", url, className: _className }) => {
  const urls = {
    LinkedIn: `https://www.linkedin.com/share?url=${encodeURIComponent(url)}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    Facebook: process.env.GATSBY_FACEBOOK_APP_ID
      ? `https://www.facebook.com/dialog/share?app_id=${
          process.env.FACEBOOK_APP_ID
        }&display=page&href=${encodeURIComponent(
          url
        )}&redirect_uri=${encodeURIComponent(`https://www.facebook.com`)}`
      : `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
  };

  const classes =
    "flex h-7 w-7 items-center justify-center rounded-full bg-purple p-2 duration-200 hover:bg-blue";

  return (
    <div className={clsx("flex items-center space-x-4", _className)}>
      {title && <span className="font-serif text-base">{title}</span>}
      <AppLink to={urls.LinkedIn} target="_blank" className={classes}>
        <Icon name="linkedin" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Twitter} target="_blank" className={classes}>
        <Icon name="twitter" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Facebook} target="_blank" className={classes}>
        <Icon name="facebook" className="h-full w-full text-white" />
      </AppLink>
    </div>
  );
};

export default Share;
