import { useStaticQuery, graphql } from "gatsby";
import { resolveLinks } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          # navigation
          linkList {
            ... on Craft_linkList_link_BlockType {
              uid
              typeHandle
              linkObject {
                url
                text
              }
            }
            ... on Craft_linkList_sublink_BlockType {
              uid
              typeHandle
              linkObject {
                url
                text
              }
            }
          }
          # legal
          text0
          link0 {
            url
            text
          }
          # social
          social {
            ... on Craft_social_account_BlockType {
              uid
              platform
              accounturl
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { linkList, text0, link0, social } = craft.globalSet;

  const resolveSocial = socials => {
    return socials.map(s => {
      return {
        uid: s.uid,
        platform: s.platform,
        url: s.accounturl,
      };
    });
  };

  return {
    links: resolveLinks(linkList),
    copyright: text0,
    privacy: {
      url: link0.url,
      text: link0.text,
    },
    social: resolveSocial(social),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
