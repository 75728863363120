import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import Icon from "./Icon";

const Wrapper = ({ condition, onClick, className: _className, children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={clsx("group inline-flex items-center", _className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={clsx("group inline-flex items-center", _className)}>
          {children}
        </div>
      )}
    </>
  );
};

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse = false,
  icon = "arrow",
  underline = true,
  color = true,
  onClick,
}) => {
  return (
    <Wrapper condition={to} className={_className} onClick={onClick}>
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={clsx(
            "icon flex-shink-0 mr-1 h-4 w-4 duration-200 group-hover:-translate-x-2",
            {
              "rotate-180": icon,
              "group-hover:text-orange": color,
            }
          )}
        >
          <Icon name={icon} className="h-4 w-4" />
        </span>
      )}
      {/* label */}
      <span
        className={clsx(
          "font-normal leading-normal underline decoration-2 underline-offset-4 duration-200",
          {
            "decoration-orange hover:decoration-orange": underline && color,
            "decoration-transparent hover:decoration-orange":
              !underline && color,
            "decoration-transparent hover:decoration-white":
              underline && !color,
            "decoration-transparent": !underline && !color,
          }
        )}
      >
        {children}
      </span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span
          className={clsx(
            "icon flex-shink-0 ml-1 h-4 w-4 duration-200 group-hover:translate-x-2",
            {
              "group-hover:text-orange": color,
            }
          )}
        >
          <Icon name={icon} className="h-4 w-4" />
        </span>
      )}
    </Wrapper>
  );
};

export default ButtonAlt;
