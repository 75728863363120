import { useStaticQuery, graphql } from "gatsby";
import { resolveImage } from "@dataResolvers";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          # meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # CTA
          heading0
          descriptor0
          link0 {
            url
            text
          }
          # default testimonial section
          heading1
          descriptor1
          # funder testimonial section
          heading4
          descriptor4
          # nonprofit testimonial section
          heading5
          descriptor5
          # services section
          heading2
          descriptor2
          # blog section
          heading3
          descriptor3
          # DEI statement
          copy0
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    metaTitle,
    metaImage,
    // cta
    heading0,
    descriptor0,
    link0,
    // testimonials
    heading1,
    descriptor1,
    heading4,
    descriptor4,
    heading5,
    descriptor5,
    // services
    heading2,
    descriptor2,
    // blog
    heading3,
    descriptor3,
    // DEI statement
    copy0,
  } = craft.globalSet;
  return {
    meta: {
      metaTitle,
      metaImage: resolveImage(metaImage),
    },
    cta: {
      heading: heading0,
      description: descriptor0,
      link: {
        url: link0.url,
        text: link0.text,
      },
    },
    defaultTestimonials: {
      heading: heading1,
      description: descriptor1,
    },
    funderTestimonials: {
      heading: heading4,
      description: descriptor4,
    },
    nonprofitTestimonials: {
      heading: heading5,
      description: descriptor5,
    },
    servicesCopy: {
      heading: heading2,
      description: descriptor2,
    },
    blogCopy: {
      heading: heading3,
      description: descriptor3,
    },
    deiStatement: copy0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
