import React, { useState } from "react";
import clsx from "clsx";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import Text from "./Text";

const Input = ({
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    // case "file":
    //   Field = InputFile;
    //   break;
    default:
      Field = InputText;
      break;
  }

  if (group?.length) {
    return (
      <fieldset className="flex flex-wrap flex-gap-4">
        <legend
          className={clsx("mb-2 text-sm", {
            hidden: !label,
            "sr-only": label && hideLabel,
          })}
        >
          {label} {required && <span>*</span>}
        </legend>
        {group?.map((_g, i) => {
          const subError = error?.includes(_g?.name || name);
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${id}-${name}--${i}`}
              className={clsx(
                {
                  "mb-2 flex items-center": singleLine || _g?.singleLine,
                  "mb-2 flex flex-row-reverse items-center justify-end":
                    type === "checkbox" || type === "radio",
                },
                _className
              )}
            >
              <label
                htmlFor={_g?.id || name}
                className={clsx(
                  "block leading-tight underline decoration-transparent decoration-2 underline-offset-4 duration-200",
                  {
                    "mb-2":
                      !singleLine && type !== "checkbox" && type !== "radio",
                    "ml-2": type === "checkbox" || type === "radio",
                    "mr-2": singleLine || _g?.singleLine,
                    "decoration-purple": subError,
                  }
                )}
              >
                {_g?.label}{" "}
                {_g.required && <span className="text-purple">*</span>}
              </label>
              <Field
                {..._g}
                id={_g?.id || name}
                name={_g?.name || name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={subError}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                className="duration-200 focus:ring-4 focus:ring-blue"
              />
            </div>
          );
        })}
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-purple">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={_className}>
      <div
        className={clsx("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
        })}
      >
        <label
          htmlFor={id || name}
          className={clsx(
            "block leading-tight underline decoration-transparent decoration-2 underline-offset-4 duration-200",
            {
              "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
              "mr-2": singleLine,
              "ml-2": type === "checkbox" || type === "radio",
              "sr-only": hideLabel,
              "decoration-purple": hasError,
            }
          )}
        >
          {label} {required && <span className="text-purple">*</span>}
        </label>

        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
        />
      </div>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-purple">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default Input;
