import { useStaticQuery, graphql } from "gatsby";
import { resolveLinks } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          linkList {
            ... on Craft_linkList_link_BlockType {
              uid
              typeHandle
              linkObject {
                url
                text
              }
            }
            ... on Craft_linkList_sublink_BlockType {
              uid
              typeHandle
              linkObject {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { linkList } = craft.globalSet;
  return {
    links: resolveLinks(linkList),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
