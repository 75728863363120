const resolveCards = cards =>
  cards.map(card => {
    return {
      uid: card.uid,
      heading: card.heading,
      description: card.descriptor,
      link: {
        url: card.linkLink.url,
        text: card.linkLink.text,
      },
    };
  });

export default resolveCards;
