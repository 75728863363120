import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    craft {
      globalSet(handle: "gdpr") {
        ... on Craft_gdpr_GlobalSet {
          boolean0
          heading0
          copy0
          heading1
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { boolean0, heading0, copy0, heading1 } = craft.globalSet;
  return {
    enabled: boolean0,
    heading: heading0,
    copy: copy0,
    button: heading1,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  // const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(response);
};

export default useData;
