import resolveImage from "./resolveImage";

const resolveClients = clients => {
  if (clients && clients.length > 0) {
    return clients.map(client => {
      const { title, uid, image0 } = client;
      return {
        title,
        uid,
        image: resolveImage(image0),
      };
    });
  }
  return null;
};

export default resolveClients;
