import React from "react";
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color = "yellow",
  to,
  title,
  type = "button",
  onClick,
  linkObj: LinkObj = AppLink,
  children,
  fullWidth,
  size = "md",
  rounded,
  className: _className,
}) => {
  const defaultClassName = `font-normal inline-flex justify-center items-center cursor-pointer items-center relative z-0 group leading-tighter duration-200 border focus:ring-4 focus:ring-blue`;

  const sizes = {
    fluid: "py-2 px-2.5 text-sm",
    xxs: "p-2 text-xxs",
    xs: "px-3 py-2 text-xs",
    sm: "px-4 py-3 text-xs",
    md: "px-5 py-3 text-sm",
    lg: "px-6 py-4 text-lg",
  };

  const colors = {
    yellow:
      "bg-yellow hover:bg-black text-black hover:text-white border-black hover:border-white",
    transparent:
      "bg-transparent hover:bg-black text-black hover:text-white border-black",
    black: "bg-black hover:bg-yellow text-white hover:text-black border-black",
    white:
      "bg-transparent hover:bg-yellow text-white hover:text-black border-white hover:border-yellow",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size] || "px-5 py-3 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={clsx(classes, _className)}
        onClick={onClick}
      >
        <span className="relative z-10 flex text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(classes, _className)}
      onClick={onClick}
    >
      <span className="relative z-10 flex text-center">{children}</span>
    </button>
  );
};

export default Button;
