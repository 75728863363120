import React, { useEffect, useState } from "react";
import clsx from "clsx";

const InputCheckbox = ({
  name,
  placeholder,
  formState,
  hasError,
  checked,
  id,
  value,
  groupState,
  className: _className,
}) => {
  const [form, setForm] = formState;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [checkedList, setCheckedList] = groupState || useState(false);

  const updateChecklist = e => {
    const add = e.target.checked;

    if (add) {
      setCheckedList([...checkedList, e.target.value]);
    } else {
      const removeEntry = checkedList?.filter(i => i !== e.target.value);
      setCheckedList(removeEntry);
    }
  };

  useEffect(() => {
    if (groupState) {
      setForm({
        ...form,
        [name]: checkedList,
      });
    }
  }, [checkedList]);

  return (
    <input
      type="checkbox"
      name={name}
      id={id || name}
      value={value}
      placeholder={placeholder}
      className={clsx(
        "form-check-input after:checked:border-inset appearance-none border-2 border-black bg-white p-2 transition duration-200 checked:bg-purple after:checked:absolute after:checked:left-[0.45rem] after:checked:top-[0.1rem] after:checked:box-border after:checked:h-[0.75rem] after:checked:w-[0.375rem] after:checked:rotate-45 after:checked:border-b-2 after:checked:border-r-2",
        {
          "bg-orange": hasError,
        },
        _className
      )}
      onChange={e => {
        if (groupState) {
          updateChecklist(e);
        } else {
          setForm({
            ...form,
            // ! this is only used in the ContactForm component right now,
            // ! so it only returns the required string for the Contacts table
            // ! you will need to change it for use in other forms
            [name]: e.target.checked ? "subscribed" : "unsubscribed",
          });
        }
      }}
      checked={checked}
    />
  );
};

export default InputCheckbox;
